<template>
  <div class="px-5">
    <div class="d-flex align-center mb-5">
      <v-row class="mt-1">
        <v-col cols="12">
          <v-card
            class="pa-5 rounded-lg"
            :style="{
              backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
            }"
          >
            <v-row>
              <v-col cols="12">
                <div class="d-flex">
                  <div class="rounded-pill purple lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3">
                    <v-icon dense color="white">mdi-cash-multiple</v-icon>
                  </div>
                  <div class="ml-1 d-flex justify-center align-center">
                    <span class="font-weight-bold align-center"> Lainnya </span>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-divider class="my-4" />
            <v-row>
              <v-col cols="12" class="px-1">
                <div class="pa-3 rounded-lg evaluation-3" style="height: 100%; background-color: #3d8d7a">
                  <router-link to="/others/wakaf">
                    <div class="mt-2 d-flex justify-center">
                      <v-icon dense class="pa-2 rounded-circle" style="background-color: white; color: #3d8d7a"> mdi-hand-coin </v-icon>
                    </div>
                    <div class="mt-2 d-flex justify-center">
                      <span class="text-caption text-center white--text text--lighten-5 mt-1"> Wakaf </span>
                    </div>
                  </router-link>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Others",
};
</script>
